import React from "react"
import { graphql, Link } from "gatsby"
import { StructuredText } from "react-datocms"
import {
  isHeading,
  isParagraph,
  renderRule,
} from "datocms-structured-text-utils"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import {
  Container,
  Heading,
  Box,
  Text,
  Grid,
  Image,
} from "@theme-ui/components"
import { LanguageContext, languages } from "../context/langContext"
import { getStoryPath } from "../utils/path"
import slugify from "react-slugify"
import { Sticky, StickyContainer } from "react-sticky"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js"
import "swiper/swiper-bundle.min.css"

const PillarPage = ({ data: { pillar, site }, pageContext }) => {
  const locale = pageContext.locale
  const providerLanguage = languages[locale]
  const i18nPaths = pillar._allSlugLocales.map(l => {
    return {
      locale: l.locale,
      value: getStoryPath(pillar, l.locale),
    }
  })
  const contentIndex = pillar.body.value.document.children.filter(
    node => node.type === "heading" && node.level === 2
  )

  return (
    <LanguageContext.Provider value={providerLanguage}>
      <Layout locale={locale} i18nPaths={i18nPaths}>
        <SEO title={pillar.title} lang={locale} />
        <Header locale={locale} theme="light" />
        <Container sx={{ mb: [7, "10rem"] }}>
          <Box sx={{ mb: "6rem" }}>
            <Heading as="h1" variant="h1">
              {pillar.title}
            </Heading>
          </Box>
          <Grid columns={[1, 1, 1, "3fr 7fr"]} gap={[64]}>
            <StickyContainer>
              <Sticky>
                {({ style }) => (
                  <Box
                    as="ol"
                    style={style}
                    sx={{ listStyle: "none", counterReset: "item" }}
                  >
                    <Text
                      as="p"
                      sx={{ fontWeight: "bold", fontSize: 3, mb: 3, pt: 3 }}
                    >
                      Indice contenuti
                    </Text>
                    {contentIndex.map(heading => (
                      <>
                        {heading.children.map(h2 => (
                          <Box
                            as="li"
                            sx={{
                              display: "flex",
                              counterIncrement: "item",
                              mb: 2,
                              "&::before": {
                                content: `counters(item,".") ". "`,
                                mr: 1,
                              },
                              paddingLeft: 0,
                              a: {
                                textDecoration: "none",
                                "&:hover": {
                                  textDecoration: "underline",
                                },
                              },
                            }}
                          >
                            <Link to={`#${slugify(h2.value)}`}>{h2.value}</Link>
                          </Box>
                        ))}
                      </>
                    ))}
                  </Box>
                )}
              </Sticky>
            </StickyContainer>
            <Box
              sx={{
                pt: 3,
                blockquote: {
                  fontWeight: "bold",
                  my: 5,
                  p: { fontSize: 3, color: "primary", textAlign: "center" },
                },
              }}
            >
              <StructuredText
                data={pillar.body}
                renderBlock={({ record }) => {
                  switch (record.__typename) {
                    case "DatoCmsImageBlock":
                      return (
                        <Box mt={5} mb={5}>
                          <Image sx={{ mb: 3 }} src={record.image.url} />
                        </Box>
                      )
                    default:
                      return null
                  }
                }}
                customRules={[
                  renderRule(
                    isHeading,
                    ({ adapter: { renderNode }, node, children, key }) => {
                      return renderNode(
                        () => {
                          return (
                            <Text
                              as={`h${node.level}`}
                              variant={`h${node.level}`}
                              sx={{ pt: 3 }}
                              color={"primary"}
                              id={`${slugify(node.children[0].value)}`}
                            >
                              {children}
                            </Text>
                          )
                        },
                        { key },
                        children
                      )
                    }
                  ),
                  renderRule(
                    isParagraph,
                    ({ adapter: { renderNode }, node, children, key }) => {
                      return renderNode(
                        () => {
                          return (
                            <Text as="p" mb={3}>
                              {children}
                            </Text>
                          )
                        },
                        { key },
                        children
                      )
                    }
                  ),
                ]}
              />
            </Box>
          </Grid>
          {pillar.links.length > 0 && (
            <Box sx={{ mt: 6 }}>
              <Heading as="h4" variant="h3" sx={{ textAlign: "center", mb: 5 }}>
                <LanguageContext.Consumer>
                  {t => t.relatedArticles}
                </LanguageContext.Consumer>
              </Heading>
              <Swiper
                spaceBetween={32}
                slidesPerView={3}
                breakpoints={{
                  // when window width is >= 320px
                  320: {
                    slidesPerView: 1.75,
                    spaceBetween: 20,
                  },
                  // when window width is >= 480px
                  480: {
                    slidesPerView: 1.5,
                    spaceBetween: 32,
                  },
                  // when window width is >= 640px
                  640: {
                    slidesPerView: 3,
                    spaceBetween: 32,
                  },
                }}
                pagination={{ clickable: true }}
                modules={[Pagination]}
              >
                {pillar.links.map(link => (
                  <SwiperSlide>
                    <Box>
                      <OutboundLink
                        href={link.url}
                        target={link.blank ? "blank" : null}
                        rel={link.blank ? "noopener nofollow" : null}
                      >
                        <Box sx={{ borderRadius: "lg" }}>
                          {link.image && (
                            <Image
                              src={link.image.url}
                              alt={link.image.alt}
                              sx={{ borderRadius: "lg", mb: 3 }}
                            />
                          )}
                          {link.anchorText}
                        </Box>
                      </OutboundLink>
                    </Box>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          )}
        </Container>
      </Layout>
    </LanguageContext.Provider>
  )
}

export default PillarPage

export const query = graphql`
  query PillarContentQuery($id: String!, $locale: String!) {
    site: datoCmsSite {
      locales
    }
    pillar: datoCmsPillarContent(id: { eq: $id }, locale: { eq: $locale }) {
      title
      body {
        value
        blocks {
          __typename
          ... on DatoCmsImageBlock {
            id: originalId
            image {
              url
              alt
            }
            model {
              apiKey
            }
          }
        }
      }
      links {
        url
        anchorText
        blank
        image {
          url
          alt
        }
      }
      _allSlugLocales {
        value
        locale
      }
    }
  }
`
